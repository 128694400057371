<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-modal
        id="modal-pdf-viewer"
        cancel-variant="outline-secondary"
        ok-disabled
        cancel-title="Close"
        centered
        title=""
        ref="modalPdfViewer"
        :hide-footer="true"
        size="lg"
    >
      <pdf-viewer></pdf-viewer>
    </b-modal>
    <!-- Filters -->
    <finance-list-filters
        :organisation-filter.sync="organisationFilter"
        :status-filter.sync="statusFilter"
        :organisation-options="organisationOptions"
        :status-options="statusOptions"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refInvoicesListTable"
          class="position-relative"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
            #{{ data.value }}
        </template>

        <template #cell(orderID)="data">
          <b-link
              :to="{ name: 'app-order-detail', params: { id: data.value }}"
              :id="`order-detail-view-action-row-${data.value}`"
          >
            #{{ data.value }}
          </b-link>
        </template>

        <template #cell(status)="data">
          <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${resolveTypeVariant(data.item.status).variant}`"
          >
            <feather-icon
                :icon="resolveTypeVariant(data.item.status).icon"
            />
          </b-avatar>
          <b-tooltip
              :target="`invoice-row-${data.item.id}`"
              placement="top"
          >
            <p class="mb-0">
              {{ resolveTypeVariant(data.item.status).tooltip }}
            </p>
          </b-tooltip>
        </template>

        <template #cell(order)="data">
          <div vertical-align="center">
            {{ data.item.order.policyHolderRelation.type === 1 ? getFullName(data.item.order.policyHolderRelation) : data.item.order.policyHolderRelation.companyName }}
          </div>
        </template>

        <template #cell(company)="data">
          {{ data.item.company.name }}
        </template>

        <template #cell(type)="data">
          {{ data.item.file.fileName }}
        </template>

        <template #cell(amount)="data">
          {{data.item.currency}} {{ parseFloat(data.item.total).toLocaleString("nl-NL") }}
        </template>

        <template #cell(timestamp)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{formatDateTime(data.value)}}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
                :id="`pdf-row-${data.item.id}-preview-icon`"
                class="cursor-pointer mx-1"
                icon="EyeIcon"
                size="16"
                style="color:#fdb813"
                @click="viewDocument(data.item.fileURL)"
            />
            <b-link
              :href="data.item.fileURL"
              target="_blank"
            >
              <feather-icon
                  :id="`invoice-row-${data.item.id}-download-icon`"
                  class="cursor-pointer mx-1"
                  icon="DownloadIcon"
                  size="16"
                  style="color:#82868b"
              />
              <b-tooltip
                  :target="`invoice-row-${data.item.id}-download-icon`"
                  placement="top"
              >
                <p class="mb-0">
                  Factuur downloaden
                </p>
              </b-tooltip>
            </b-link>
            <b-link
                @click="invoicedPaid(data.item.id)"
            >
              <feather-icon
                  v-if="data.item.status === 1 && $can('update', 'invoice')"
                  :id="`invoice-row-${data.item.id}-status-icon`"
                  class="cursor-pointer"
                  size="16"
                  icon="CreditCardIcon"
                  style="color:#28c76f"
              />
              <b-tooltip
                  v-if="$can('update', 'invoice')"
                  :target="`invoice-row-${data.item.id}-status-icon`"
                  placement="top"
              >
                <p class="mb-0">
                  Status aanpassen naar betaald
                </p>
              </b-tooltip>
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink, BPagination, BTooltip, BAvatar, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { formatDateTime, formatDate } from '@core/utils/utils'
import financeStoreModule from '@/views/pages/finance/financeStoreModule'
import useFinanceList from '@/views/pages/finance/useFinanceList'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import PdfViewer from '@/views/pages/pdf-viewer/PdfViewer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FinanceListFilters from './FinanceListFilters.vue'

export default {
  components: {
    FinanceListFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BAvatar,
    vSelect,
    BOverlay,
    PdfViewer,
  },
  data() {
    return {
      organisationOptions: [],
      password: '',
    }
  },
  beforeMount() {
    this.getOrganisations()
  },
  methods: {
    viewDocument(url) {
      this.$store.commit('app/UPDATE_PDF_URL', url)
      this.$refs.modalPdfViewer.show()
    },
    getOrganisations() {
      store
        .dispatch('app-invoices/fetchOrganisations', {
          sortBy: 'name',
          sortDesc: false,
        })
        .then(response => {
          this.organisationOptions = response.data.items
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen organisaties',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
    invoicedPaid(id) {
      store.dispatch('app-invoices/updateInvoice', id)
        .then(() => {
          this.refetchData()
          this.$swal({
            icon: 'success',
            title: 'Factuur status!',
            text: 'De status van de factuur is aangepast.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Factuur status!',
            text: `${errorAndSuccesDefaultMessage.saveDataError}`,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
  },
  setup() {
    const INVOICES_APP_STORE_MODULE_NAME = 'app-invoices'

    // Register module
    if (!store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.registerModule(INVOICES_APP_STORE_MODULE_NAME, financeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICES_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICES_APP_STORE_MODULE_NAME)
    })

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoicesListTable,
      statusFilter,
      organisationFilter,
      isSortDirDesc,
      resolveTypeVariant,
      statusOptions,
      showOverlay,
      refetchData,
      relationOptions,
    } = useFinanceList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      refInvoicesListTable,
      statusFilter,
      organisationFilter,
      isSortDirDesc,
      resolveTypeVariant,
      statusOptions,
      showOverlay,
      refetchData,
      formatDateTime,
      relationOptions,
      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
