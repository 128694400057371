import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refInvoicesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Order ID', key: 'orderID', sortable: true },
    { label: 'Verzekeringsnemer', key: 'order', sortable: true },
    { label: 'Organisatie', key: 'company', sortable: true },
    { label: 'Bedrag', key: 'amount', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Aangemaakt op', key: 'timestamp', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const organisationFilter = ref(null)
  const statusFilter = ref(null)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoicesListTable.value ? refInvoicesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refInvoicesListTable.value.refresh()
  }

  watch([currentPage, perPage, organisationFilter, statusFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('app-invoices/fetchInvoices', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
        status: statusFilter.value,
        companyID: organisationFilter.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalUsers.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen facturen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const resolveTypeVariant = status => {
    if (status === 1) return { tooltip: 'Niet betaald', icon: 'CreditCardIcon', variant: 'warning' }
    if (status === 2) return { tooltip: 'Betaald', icon: 'CreditCardIcon', variant: 'success' }
    return { tooltip: 'Niet betaald', icon: 'CreditCardIcon', variant: 'warning' }
  }

  const statusOptions = [
    { label: 'Niet betaald', value: 1 },
    { label: 'Betaald', value: 2 },
  ]

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    refInvoicesListTable,
    statusFilter,
    organisationFilter,
    resolveTypeVariant,
    statusOptions,
    refetchData,
    showOverlay,
  }
}
